import { Cross2Icon, HamburgerMenuIcon } from '@radix-ui/react-icons'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { Dialog, DialogClose, DialogContent, DialogTrigger } from './dialog'

const Navbar: React.FC = () => {
  const location = useLocation()
  // Condition pour vérifier si on est sur la page d'accueil
  const isHomePage = location.pathname === '/'
  return (
    <nav>
      <div className="px-4 py-4 flex justify-end items-center text-custom-color">
        <div className="hidden md:flex space-x-4">
          {!isHomePage && <a href="/">Accueil</a>}
          <a
            href="mailto:beatrice.bu@gmail.com?subject=RSVP Mariage&body=To come or not to come? That's the question!"
            className="border border-custom-color px-4 rounded-md hover:bg-black hover:text-white transition-colors"
          >
            RSVP
          </a>
          <a href="/infos">Infos pratiques</a>
          <a
            href="https://leetchi.com/fr/c/mariage-melissa-charles"
            target="_blank"
            rel="noopener noreferrer"
          >
            Leetchi
          </a>
          <a href="/photos">Photos</a>
        </div>
      </div>

      {/* Bouton pour le menu mobile */}
      <Dialog>
        <DialogTrigger className="fixed bottom-4 left-4 md:hidden z-20">
          <HamburgerMenuIcon className="h-6 w-6 z-20" />
        </DialogTrigger>

        <DialogContent className="fixed bottom-0  bg-white p-4  w-full rounded-t-xl">
          <DialogClose className="absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100 focus:outline-none">
            <Cross2Icon className="h-4 w-4" />
            <span className="sr-only">Fermer</span>
          </DialogClose>

          <div className="flex flex-col space-y-2 text-custom-color">
            {!isHomePage && <a href="/">Accueil</a>}
            <a
              href="mailto:beatrice.bu@gmail.com?subject=RSVP Mariage&body=To come or not to come? That's the question!"
              className=" hover:text-black hover:font-bold transition-colors"
            >
              RSVP
            </a>
            <a href="/infos">Infos pratiques</a>
            <a
              href="https://leetchi.com/fr/c/mariage-melissa-charles"
              target="_blank"
              rel="noopener noreferrer"
            >
              Leetchi
            </a>
            <a href="/photos">Photos</a>
          </div>
        </DialogContent>
      </Dialog>
    </nav>
  )
}
export default Navbar
