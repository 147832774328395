import { S3Client, ListObjectsCommand } from '@aws-sdk/client-s3'
import { useEffect, useState } from 'react'

const s3Client = new S3Client({
  region: 'eu-west-3',
  credentials: {
    accessKeyId: 'AKIA4ETI3TZRUYPAECV3',
    secretAccessKey: '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'
  }
})
// Définir le type pour les objets S3
interface S3Object {
  Key: string
  // Ajoute d'autres propriétés si nécessaire
}

const PhotoGallery = ({ photosUpdated }: { photosUpdated: boolean }) => {
  const [photos, setPhotos] = useState<S3Object[]>([])

  // Fonction pour récupérer les photos depuis S3
  const fetchPhotos = async () => {
    try {
      const command = new ListObjectsCommand({
        Bucket: 'mariagemetc'
      })

      const data = await s3Client.send(command)
      console.log(data.Contents) // Voici la liste des objets
      // Vérifie si data.Contents n'est pas undefined
      if (data.Contents) {
        setPhotos(data.Contents as S3Object[])
      }
    } catch (error) {
      console.error('Error fetching photos: ', error)
    }
  }

  // Effect pour récupérer les photos lors du chargement du composant
  useEffect(() => {
    fetchPhotos()
  }, [photosUpdated])

  return (
    <div>
      <h2 className="text-lg font-bold mb-4">Galerie de Photos</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {photos.map(photo => (
          <div key={photo.Key} className="overflow-hidden rounded-lg shadow">
            <img
              src={`https://mariagemetc.s3.eu-west-3.amazonaws.com/${photo.Key}`}
              alt={`Uploaded ${photo.Key}`}
              className="w-full h-auto"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PhotoGallery
