import React from 'react'

const InfosPratiques = () => {
  return (
    <div className="p-4">
      {/* Section Timeline */}
      <div className="flex flex-col md:flex-row md:space-x-8">
        {/* Tableau pour le samedi 14 juin */}
        <div className="mb-8 md:w-1/2">
          <h3 className="text-xl font-semibold mb-2 text-custom-color">Samedi 14 juin</h3>
          <table className="min-w-full border border-custom-color">
            <tbody>
              <tr>
                <td className="border border-gray-300 px-4 py-2">15H00</td>
                <td className="border border-gray-300 px-4 py-2">
                  Rendez-vous à la{' '}
                  <a
                    href="https://maps.app.goo.gl/xzaYMR4ELJcknjfU8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-custom-color underline"
                  >
                    Mairie de Bazoche-sur-Hoëne
                  </a>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">16h30</td>
                <td className="border border-gray-300 px-4 py-2">
                  Cocktail au jardin de la{' '}
                  <a
                    href="https://maps.app.goo.gl/xpyMe4eefZdJxafz5"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-custom-color underline"
                  >
                    Bergerie de Milan
                  </a>
                </td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">19h30</td>
                <td className="border border-gray-300 px-4 py-2">Dîner</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">21h30</td>
                <td className="border border-gray-300 px-4 py-2">Coucher du soleil</td>
              </tr>
              <tr>
                <td className="border border-gray-300 px-4 py-2">22h30</td>
                <td className="border border-gray-300 px-4 py-2">💃🪩🕺</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Tableau pour le samedi 15 juin */}
        <div className="mb-8 md:w-1/2">
          <h3 className="text-xl font-semibold mb-2 text-custom-color">Samedi 15 juin</h3>
          <table className="min-w-full border border-gray-300">
            <tbody>
              <tr>
                <td className="border border-gray-300 px-4 py-2">À partir de 11H00</td>
                <td className="border border-gray-300 px-4 py-2">Brunch-piscine 🤞🏼</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Note sur les hébergements */}
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2 text-custom-color">Où dormir ?</h2>
        <p>
          Nous avons répertorié quelques gîtes et maisons d'hôtes autour de la maison que
          vous pouvez trouver{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1k4jx-wgYAPimbatC9ben8Z49eB5i-WvvMh3MSQh4Zug/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="text-custom-color underline"
          >
            ici
          </a>
          . Nous vous conseillons de les réserver au plus vite.
        </p>
      </div>

      {/* Section Comment s'y rendre */}
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2 text-custom-color">
          Comment s'y rendre ?
        </h2>
        <div className="flex flex-col md:flex-row md:space-x-8">
          {/* Tableau pour la mairie */}
          <div className="mb-4 md:w-1/2">
            <h3 className="text-lg font-semibold mb-2">
              <a
                href="https://maps.app.goo.gl/xzaYMR4ELJcknjfU8"
                target="_blank"
                rel="noopener noreferrer"
                className="text-custom-color underline"
              >
                Mairie 📍
              </a>
            </h3>
            <table className="min-w-full">
              <tbody>
                <tr>
                  <td>
                    4 Rue du Presbytère <br /> 61560 Bazoches-sur-Hoëne
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Tableau pour le lieu de réception */}
          <div className="mb-4 md:w-1/2">
            <h3 className="text-lg font-semibold mb-2">
              <a
                href="https://maps.app.goo.gl/xpyMe4eefZdJxafz5"
                target="_blank"
                rel="noopener noreferrer"
                className="text-custom-color underline"
              >
                Bergerie de Milan 📍
              </a>
            </h3>
            <table className="min-w-full">
              <tbody>
                <tr>
                  <td>
                    Milan <br /> 61560 Bazoches-sur-Hoëne{' '}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p>Avec Waze et Google Map, juste taper "Milan" et vous devriez trouver.</p>
      </div>
    </div>
  )
}
export default InfosPratiques
