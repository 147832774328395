import React from 'react'

const Home: React.FC = () => {
  return (
    <div className="flex-1 relative" style={{ backgroundColor: '#fcf6ed' }}>
      {/* Conteneur pour centrer le texte */}
      <div className="flex flex-col items-center justify-center h-full min-h-[50vh] md:min-h-[70vh]">
        <h1 className="text-custom-color text-3xl md:text-5xl font-playfair uppercase tracking-wide z-10 text-center">
          Melissa et Charles
        </h1>
        <p className="text-black text-sm md:text-sm lg:text-lg mt-4 mb-8">
          Samedi 14 juin 2025 · Bergerie de Milan
        </p>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm text-custom-color">
            <div className="p-4 max-w-[250px]">
              <h3 className="font-semibold">Cérémonie</h3>
              <p>14h30 à la mairie de Bazoche-sur-Hoëne</p>
            </div>
            <div className="p-4 max-w-[250px]">
              <h3 className="font-semibold">Réception</h3>
              <p>Puis au Jardin de la Bergerie de Milan</p>
            </div>
          </div>
        </div>
        {/* <img
          src="/images/bRg.png"
          alt="bRg"
          className="absolute bottom-[5px] right-1/2 hidden lg:flex transform lg:w-[300px] opacity-80"
        /> */}
        {/* <div className="text-center hidden lg:flex transform lg:w-[300px]">
          <img src="/images/bRg.png" alt="bRg" />
        </div> */}
      </div>

      {/* Images d'aquarelles en bas */}
      <img
        src="/images/eucalyptus.png"
        alt="Flower 1"
        className="absolute -bottom-72 md:bottom-1 transform md:-translate-x-1/6 md:translate-y-1/4 w-56 md:w-64 lg:w-96 opacity-80 pointer-events-none"
      />
      <img
        src="/images/pavot.png"
        alt="Flower 2"
        className="absolute -bottom-72 md:bottom-1 right-1 transform  w-[200px] lg:w-[400px] opacity-80 pointer-events-none"
      />
    </div>
  )
}

export default Home
